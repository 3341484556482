<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      :title="`${isEdit ? '修改' : '新增'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="10px"
      center
      :close-on-click-modal="false"
      width="624px"
    >
      <el-tabs class="taps-card" v-model="form.status" @tab-click="handleClick">
        <el-tab-pane label="基础信息" name="first">
          <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <el-row>
              <el-col :span="24">
                <el-form-item label="标题" prop="title">
                  <el-input v-model="form.title" maxlength="30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="副标题" prop="subtitle">
                  <el-input v-model="form.subtitle" maxlength="60"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="封面图片" prop="coverPicUrl">
                  <el-upload
                  class="upload"
                  v-if="showPic"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="apis.ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  :headers="{
                      token: TOKEN
                  }"
                  name="file"
                  accept=".png, .jpg"
                  :limit="1"
                  :before-upload="beforeUpload"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  >
                  <img class="image-upload" src="../../../assets/images/upload_image.png" v-if="form.coverPicUrl === ''" alt="" srcset="">
                  <img v-if="form.coverPicUrl" :src="form.coverPicUrl" alt srcset width="50" height="50" />
                  <div class="upload-images-tip">
                      建议尺寸690x388px
                  </div>
                  </el-upload>
                  <!-- <div class="image-wrap" v-if="form.coverPicUrl"> -->
                  <!-- </div> -->
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item label="地址" prop="point">
                  <div class="bmap-wrap">
                    <new-map v-if="showMap" ref="bMap" :address="form.mapAnnotation" :point.sync="form.point" :isEdit="isEdit" @getPoint="onGetPoint" height="300px"></new-map>
                  </div>
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item label="查询" prop="point" :required="isRequired">
                  <div class="bmap-wrap">
                    <new-map  v-if="showMap" ref="bMap" :address.sync="form.address" :point.sync="form.point" :isEdit="isEdit" @onMapAdress="getRAdress" height="300px"></new-map>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="经度" prop="latitude">
                  <el-input v-model="form.latitude" :disabled="true" maxlength="30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="纬度" prop="longitude" >
                  <el-input v-model="form.longitude" :disabled="true" maxlength="30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="地址" prop="mapAnnotation">
                  <el-input v-model="form.mapAnnotation" maxlength="100"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="VR导航" prop="vrSupport">
                  <el-radio-group v-model="form.vrSupport">
                    <el-radio v-for="(item, index) in DROPDOWNBOX.OM_VR_SUPPORT" :key="index" :label="item.value">{{item.label}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="VR地址" prop="vrPath">
                  <el-input v-model="form.vrPath" :disabled="form.vrSupport !== 'Y'" maxlength="500"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="基础阅读量" prop="baseClickCount">
                  <el-input-number v-model="form.baseClickCount" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="置顶" prop="top">
                  <el-radio-group v-model="form.top">
                    <el-radio label="N">不置顶</el-radio>
                    <el-radio label="Y">置顶</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="详细设置" name="second">
          <el-form ref="forms" :model="form" :rules="rules">
            <el-row>
              <el-col :span="24">
                <div>分享文案（选填，微信分享给好友是会显示这里的文案）</div>
                <el-form-item prop="shareCopywriter">
                  <el-input v-model="form.shareCopywriter" type="textarea" rows="3" maxlength="100"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item prop="detail" label-width="0">
                  <div class="edit_container">
                    <!-- <quill-editor v-model="form.detail" ref="myQuillEditor" class="ql-editor editer" :options="editorOption" @ready="onEditorReady($event)" @change="onEditChange">
                    </quill-editor> -->
                    <quill-edit :detail="form.detail" @change="onEditChange" :qnLocation="apis.ImageUpload">
                    </quill-edit>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <el-button class="footer-confirm" v-if="form.status === 'first'" @click="nextButton">下一步</el-button>
        <el-button class="footer-confirm" v-if="form.status === 'second'" @click="onPreview">预览</el-button>
        <el-button class="footer-confirm" v-if="form.status === 'second'" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
     <!-- 图片预览 -->
    <preview-image ref="previewImage" :show="previewImage.visible" @closeDialog="() => {this.previewImage.visible=false}"></preview-image>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
// import Bmap from "@/components/BMap.vue";
import newMap from "../../venueMana/venue/dialog/bMap.vue";
// import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import previewImage from "./preview.vue";
import quillEdit from '@/components/quillEdit.vue'

export default {
  mixins: [mixin],
  components: {
    quillEdit,
    newMap,
    previewImage,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  // mounted() {
  //   console.log("DROPDOWNBOX.VENUE_SERVICE", this.DROPDOWNBOX.WEEK)
  // },
  data() {
    return {
      baseImage: "",
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      showMap: true,
      isRequired: true,
      showPic: true,
      editfirst: false,
      editorOption: {
        placeholder: '请输入...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['image', 'formula'], //去除video即可
          ],
        },
      },
      previewImage: {
        visible: false,
        src: "",
      },
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  methods: {
    getRAdress(address, point) {
      console.log('getRAdress-add', address)
      if (!this.editfirst) {
        this.form.mapAnnotation = address.address;
      }
     this.form.point.lat = point.lat;
     this.form.latitude = point.lat;
     this.form.point.lng = point.lng;
     this.form.longitude = point.lng;
     this.editfirst = false
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$refs.bMap.clearData();
      this.$emit('closeDialog', false)
    },
    ...mapMutations(['setVenueId', "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.$refs.forms.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    handleClick() {
      // this.showMap = false
      // this.$nextTick(() => {
      //   this.showMap = true
      //   // if (value.name === "first") {
      //   //   this.$refs.bMap.init()
      //   // }
      // })
    },
    // 地图选点成功返回
    onGetPoint(point, address) {
      this.form.point.lat = point.lat;
      this.form.point.lng = point.lng;
      this.form.mapAnnotation = address;
    },
    // 下一步
    nextButton() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.status = 'second'
        }
      })
    },
    /**
     * @function 文本编辑器
    */
    onEditorReady(editor) {
      console.log(editor)
    },
    onEditChange(value) {
      this.form.detail = value
      this.$refs.forms.validateField("detail");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.editfirst = false
      this.$refs.forms.validate((valida) => {
        if (valida) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              const formData = { ...this.form };
              formData.latitude = formData.point.lat;
              formData.longitude = formData.point.lng;
              this.$http.post(this.isEdit ? apis.omscenicspotUpdate : apis.omscenicspotSave, formData).then((res) => {
                if (res.data.code === 0) {
                  this.closeDialog()
                }
              })
            } else {
              this.form.status = 'first'
            }
          })
        }
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.omscenicspotGet}/${data[0].id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = {
            ...res.data.rows,
            ...{
              status: 'first',
              longitude: res.data.rows.longitude,
              latitude: res.data.rows.latitude,
              point: {
                lng: Number(res.data.rows.longitude),
                lat: Number(res.data.rows.latitude),
              },
            },
          };
          this.showMap = false
          this.$nextTick(() => {
            this.showMap = true
            this.editfirst = true
          })
        }
      });
    },
    beforeUpload(file) {
      // 格式校验
      const isFormat = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
      // 校验图片大小
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过2MB");
      }
      if (!isFormat) {
        this.$message.warning("上传的图片类型错误");
      }
    },
    // 封面图片成功
    uploadSuccess(res) {
      this.form.coverPicUrl = res.data.url
      this.form.coverPic = res.data.path
      this.showPic = false
      this.$nextTick(() => {
        this.showPic = true
      })
      this.$refs.form.validate('coverPicUrl');
    },
    // 封面图片失败
    uploadError(res) {
      console.log(res);
    },
    openDialog() {
      if (!this.isEdit) {
        this.showMap = false
        this.$nextTick(() => {
          this.showMap = true
          // this.$refs.bMap.init()
        })
      }
    },
    updatePoint(point) {
      this.form.point = point
      this.showMap = false
      this.$nextTick(() => {
        this.showMap = true
      })
      console.log(point)
    },
    // 预览
    onPreview() {
      this.$refs.forms.validate((valida) => {
        if (valida) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.previewImage.visible = true
              this.$nextTick(() => {
                this.$refs['previewImage'].getPreviewData(this.form)
              })
            } else {
              this.form.status = 'first'
            }
          })
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
.convenienceDialog {
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .edit_container {
    .ql-snow {
      .ql-tooltip {
        left: 10px !important;
      }
    }
  }
}
</style>
