/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-06-12 17:50:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  validatePhone = (rule, value, callback) => {
    var phone=value.replace(/\s/g, "");//去除空格
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
    let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    if(!value){
        callback([new Error('请输入联系电话')]);
    } else {
        if(!regs.test(phone)){
            callback([new Error('手机号输入不合法')]);
        } else {
            callback();
        }
   }
  }
  constructor(type) {
    if (type === 'form') {
      this.status = 'first'
      this.baseClickCount = ''
      this.coverPic = ''
      this.latitude = ''
      this.longitude = ''
      this.mapAnnotation = ''
      this.address = ''
      this.subtitle = ''
      this.top = ''
      this.detail = ''
      this.shareCopywriter = ''
      this.coverPicUrl = ''
      this.vrPath = ''
      this.vrSupport = ''
      // 自己用
      this.point = {}
    } else if (type === 'rule') {
      this.title = [{ required: true, message: '请输入标题', trigger: 'blur' }]
      this.subtitle = [{ required: true, message: '请输入副标题', trigger: 'blur' }]
      this.coverPicUrl = [{ required: true, message: '请选择封面图片', trigger: ['change', 'blur'] }]
      this.point = [{ required: true, message: '请选择地址', trigger: ['change', 'blur'] }]
      this.baseClickCount = [{ required: true, message: '请输入基础阅读量', trigger: ['change', 'blur'] }]
      this.top = [{ required: true, message: '请选择是否置顶', trigger: ['change', 'blur'] }]
      this.detail = [{ required: true, message: '请输入详细介绍', trigger: ['change', 'blur'] }]
      this.mapAnnotation = [{ required: true, message: '请输入地址', trigger: ['change', 'blur'] }]
    }
  }
}
export default searchFrom
